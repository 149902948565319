import * as Sentry from '@sentry/browser';

const { MODE, VITE_SENTRY_DSN, VITE_SENTRY_TRACES_SAMPLE_RATE } = import.meta.env;

// ATTENTION:
// import.meta.env.RAILS_ENV は、本番環境などで取得すると "production" と
// ダブルクォーテーションで括られた文字列が出力される。（testingも同様）
if (MODE === 'testing' || MODE === 'production') {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,

    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: VITE_SENTRY_TRACES_SAMPLE_RATE,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    environment: MODE,
  });
}
