import { Application } from '@hotwired/stimulus';

import { registerControllers } from 'stimulus-vite-helpers';

import PasswordVisibility from 'stimulus-password-visibility';
import Reveal from 'stimulus-reveal-controller';
import TextareaAutogrow from 'stimulus-textarea-autogrow';

const application = Application.start();
application.register('password-visibility', PasswordVisibility);
application.register('reveal', Reveal);
application.register('textarea-autogrow', TextareaAutogrow);

const controllers = import.meta.glob('./**/*_controller.js', { eager: true });
registerControllers(application, controllers);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application }; // eslint-disable-line import/prefer-default-export
